.about-us-page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

.about-us {
    display: flex;
    flex-direction: column;
    flex: 1; /* Occupy available vertical space */
    padding: 32px 48px;
    background-color: #E1EBF6;
    border-bottom: 1px solid #e8e8e8;
}

.about-us h1 {
    font-size: 24px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    margin-bottom: 16px;
}
