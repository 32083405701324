.privacy-policy-terms {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: 32px 48px;
    border-bottom: 1px solid #e8e8e8;
}

.privacy-policy h1 {
    font-size: 24px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    margin-bottom: 16px;
}

.privacy-policy h3 {
    font-size: 20px;
    font-family: "Plus Jakarta Sans";
    font-weight: 700;
    margin-bottom: 16px;
}

.privacy-policy p {
    font-size: 14px;
    font-family: "Plus Jakarta Sans";
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 16px;
}

.privacy-policy a {
    color: #2039c9;
    text-decoration: none;
}

.privacy-policy a:hover {
    color: #192c9a;
}

@media (max-width: 768px) {
    .privacy-policy {
        padding: 32px 16px;
    }
}